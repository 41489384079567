import { useApi } from '~/composables/useApi';

export const useFtSaveInStockProductAlert = () => {
  const { query } = useApi();

  const saveInStockProductAlert:any = async (product_id, productConfiguration) =>{
    const myGraphqlQuery = `
    mutation saveInStockProductAlert($product_id: String, $selected_options: [ID!]){
        saveInStockProductAlert(product_id: $product_id, selected_options: $selected_options){
        error
        message
      }
    }
    `;  
    
    const { data } = await query(myGraphqlQuery, {product_id: product_id, selected_options: productConfiguration});

    return {
      data,
    };
  }
  
  return {
    saveInStockProductAlert,
  }
}


export default useFtSaveInStockProductAlert;