






















































import { 
  ref,
  defineComponent,
} from "@nuxtjs/composition-api";
import { 
  SfCheckbox, 
  SfRadio,
} from '@storefront-ui/vue';
import { merge } from "lodash-es";
import FtSelect from "~/components/customSFUI/vue/src/components/molecules/FtSelect/FtSelect.vue"; 

export default defineComponent({
  name: "ProductCustomOption",
  components: {
    SfRadio,
    SfCheckbox,
    FtSelect,
  },
  props: ["product"],
  setup(props,{emit}) {
    const radio_custom_option = ref("");
    const dropdown_custom_option = ref({});
    const checkbox_custom_option:any = ref([]);
    const checkbox_acknowledgment:any = ref([]);

    if(props.product.options){
      for (const { option_id } of props.product.options) {
        dropdown_custom_option.value =  merge({}, dropdown_custom_option.value, { [option_id] : "" });
      }
    }

    const addSelectedOptionsToArray = () => {
      let selected_options:any = [];
      let chkbox_option_sort = [];
      let selected_options_normal:any = [];
      let customOptionPrice:any = 0;
      props.product.options.forEach(option => {
        
        if(option.radio_option && radio_custom_option.value){
          for (const {option_type_id,price} of option.radio_option) {
            if(`/${option_type_id}` == radio_custom_option.value){
              customOptionPrice += price; 
            }
          }
          let buff = Buffer.from(`custom-option/${option.option_id}${radio_custom_option.value}`,'utf8').toString('base64');
          selected_options.push(buff);
          selected_options_normal.push(radio_custom_option.value.substr(1));
        }
        if(option.checkbox_option && checkbox_custom_option.value.length){
          checkbox_custom_option.value.forEach(ckbox_value => {
            chkbox_option_sort.push(ckbox_value);
          });
          /**  
             * need to sort again the checkbox value because it will based on which checkbox selected first to have different queue value,
             * and it will causes 2 cart items with the same checkbox option but different queue, so need to sort it to have 2 cart items combine to 1.
          */
          chkbox_option_sort.sort().forEach(ckbox_sort_value => {
            for (const {option_type_id,price} of option.checkbox_option) {
              if(`/${option_type_id}` == ckbox_sort_value){
                customOptionPrice += price; 
              }
            }
            let buff = Buffer.from(`custom-option/${option.option_id}${ckbox_sort_value}`,'utf8').toString('base64');
            selected_options.push(buff);
            selected_options_normal.push(ckbox_sort_value.substr(1));
          });
        }
        if(option.drop_down_option && dropdown_custom_option.value[option.option_id]){
          for (const {option_type_id,price} of option.drop_down_option) {
            if(`/${option_type_id}` == dropdown_custom_option.value[option.option_id]){
              customOptionPrice += price; 
            }
          }
          let buff = Buffer.from(`custom-option/${option.option_id}${dropdown_custom_option.value[option.option_id]}`,'utf8').toString('base64');
          selected_options.push(buff);
          selected_options_normal.push(dropdown_custom_option.value[option.option_id].substr(1));
        }
      });
      emit('update-custom-option-price', parseFloat(customOptionPrice).toFixed(2));
      emit('current-custom-option', selected_options_normal);
      props.product.custom_options = merge({},{selected_options:selected_options});
    };

    return {
      radio_custom_option,
      dropdown_custom_option,
      checkbox_custom_option,
      checkbox_acknowledgment,
      addSelectedOptionsToArray,
    };

  },
});
