















































































































































































import {
  SfColor,
  SfAlert,
  SfModal,
  SfPrice,
  SfRadio,
  SfCheckbox,
  SfQuantitySelector,
  SfAddToCart,
  SfLoader,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  PropType, watch,
  onBeforeMount,
  useContext,
} from '@nuxtjs/composition-api';
import {
  getBundleProducts,
  getName as getProductName,
  getPrice as getProductPrice,
  getSwatchData as getProductSwatchData,
} from '~/modules/catalog/product/getters/productGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import ProductBanner from '~/modules/fortytwo/product/components/ProductBanner.vue';
import ProductAlertMsg from '~/modules/fortytwo/product/components/ProductAlertMsg.vue';
import ProductCustomOption from '~/modules/fortytwo/customoption/components/ProductCustomOption.vue';
import ProductAcknowledgement from '~/modules/fortytwo/acknowledgement/components/ProductAcknowledgement.vue';
import BundleProductDeliveryInfo from '~/modules/fortytwo/delivery/components/product/BundleProductDeliveryInfo.vue';
import useFtProductStock from '~/modules/fortytwo/product/composables/useFtProductStock';
import { useUser } from '~/modules/customer/composables/useUser';
import useFtSaveInStockProductAlert from '~/modules/fortytwo/product/composables/useFtSaveInStockProductAlert';
import type { BundleProduct } from '~/modules/GraphQL/types';
import type { WithTypename } from '~/modules/catalog/product/types';
import { bundleProductInitialSelector } from '~/modules/fortytwo/product/helpers/bundleProduct';
import GA4AddRemoveCart from '~/modules/fortytwo/googleanalytics4/components/GA4AddRemoveCart.vue';
import GA4ViewItem from '~/modules/fortytwo/googleanalytics4/components/GA4ViewItem.vue';
import { useActiveCategoryStore } from '~/modules/fortytwo/category/stores/activecategory';
import FtSelect from "~/components/customSFUI/vue/src/components/molecules/FtSelect/FtSelect.vue";
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'FortyTwoBundleProductAddToCart',
  components: {
    SfColor,
    SfAlert,
    SfModal,
    SfPrice,
    SfRadio,
    SfCheckbox,
    SfQuantitySelector,
    SfAddToCart,
    FtSelect,
    ProductBanner,
    ProductAlertMsg,
    ProductCustomOption,
    ProductAcknowledgement,
    BundleProductDeliveryInfo,
    GA4AddRemoveCart,
    GA4ViewItem,
    SfLoader,
  },
  props: {
    product: {
      type: Object as PropType<WithTypename<BundleProduct>>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const qty = ref(1);
    const productqtytotal = computed(() => cartItem.value.length > 0 ? cart.value?.items?.filter((item) =>item.uid == cartItem.value[0].uid)[0]?.quantity ?? 0 : 0);
    const isSaleable = computed(() : Boolean => props.product?.stock_status == 'IN_STOCK' ? true: false); 
    
    const { addItem, error: cartError, loading: isCartLoading, canAddToCart, removeItem, updateItemQty, cart, load: loadCart } = useCart();
    const showAddToCartError = ref(true);
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const acknowledgementCheckedFlag = ref(true);
    const acknowledgementError = ref("");
    // to avoid duplicated event send to the GA4, while in product page, user delete or add item using sidebar cart, it will tigger 2 time event call due to sidebar cart and product page cart using the same component in own page and binding the same things which is cart quantity.
    const triggerAddRemoveCartTrack = ref(false);
    // at category page, it will store the category title and product page able to use the category title and send to GA4.
    const { activecategory } = useActiveCategoryStore();
    const addToCart = async (product, qty, displayPopOut) => {
      triggerAddRemoveCartTrack.value = true;
      if (acknowledgementCheckedFlag.value == false){
        acknowledgementError.value = "Please check all acknowledgement! ";
        return;
      }else{
        acknowledgementError.value = "";
      }
      // if(apiState.getCartId()){
      //   cartItem.value = await getCartItem(product);
      // }
      const bundleProductData = [];
      Object.keys(selectedOptions.value).forEach((selectedOptionKey) => {
        selectedOptions.value[selectedOptionKey].forEach((option)=> {
          bundleProductData.push({
            uid: option.uid,
            value: option.quantity,
          });
        });
      });

      // Only check and add the stockminsaleqty for 1st time added the item to cart
      productqtytotal.value > 0
      ? await addItem({ product: {
          ...props.product,
          bundle_options: bundleProductData,
        } as BundleProduct, 
        quantity: parseInt(qty) })

      : await addItem({ product: {
          ...props.product,
          bundle_options: bundleProductData,
        } as BundleProduct, 
        quantity: stockMinSaleQty.value > 1 ? stockMinSaleQty.value : parseInt(qty) });
      // show the Add to cart error after trigger the addItem
      showAddToCartError.value = true;
      
      if (!addToCartError.value) {
        // Get current cart item array
        // if(apiState.getCartId()){
        //   cartItem.value = await getCartItem(product);
        // }
        // Display product add to cart modal
        if (displayPopOut == 'show') {
          emit('openATCModal','add');
        }
      }
    };

    const { app } = useContext();
    const apiState = app.$vsf.$magento.config.state;
    const cartItem = ref([]);
    const currentCustomOption = ref([]);

    watch(
      () => JSON.stringify(currentCustomOption.value),
         async (newVariance) => {
          cartItem.value = [];
        }   
    );

    const getCartItem = (product) => {
      let cartItemuid = '';
      // Get Cart Item Array by current product uid
      const cartItemArr = cart.value?.items?.filter((item) =>item.product.uid == product.uid) ?? [];
      if (cartItemArr.length >= 1) {
        cartItemArr.forEach((item:any) => {
          let cart_selected_options = [];
          let current_selected_options = [];
          currentCustomOption.value.forEach(option => current_selected_options.push(option));
          // loop each cart items custom option into an array.
          for (const option of item.customizable_options) {
            // checkbox might have multiple value under checkbox type, so need extra loop to put the checkbox value into custom option array.
            if(option.type == "checkbox"){
              for (const ckb_option of option.values){
                cart_selected_options.push(ckb_option.value);
              }
            }else{
              cart_selected_options.push(option?.values[0]?.value);
            }
          }

          // loop each cart items bundle into an array.
          for (const option of item.bundle_options) {
            // checkbox might have multiple value under checkbox type, so need extra loop to put the checkbox value into custom option array.
            if(option.type == "checkbox"){
              for (const ckb_option of option.values){
                cart_selected_options.push(ckb_option.uid);
              }
              /**  
               * need to sort again the checkbox value because it will based on which checkbox selected first to have different queue value,
               * and it will causes 2 cart items with the same checkbox option but different queue, so need to sort it to have 2 cart items combine to 1.
              */
              multiSelectedOption.value.sort().forEach(multiselect_sort_value => {
                current_selected_options.push(multiselect_sort_value);
              });

            }else{
              cart_selected_options.push(option?.values[0]?.id);
              current_selected_options.push(option?.values[0]?.id);
            }
          }
          // compare current selected option with cart item custom option array value generated above. if match all then will return that cart item uid.
          if (JSON.stringify(cart_selected_options.sort()) === JSON.stringify(current_selected_options.sort())){
            cartItemuid = item.uid;
            return cartItemArr.filter((item) => item.uid === cartItemuid);
          } 
        });
        return cartItemArr.filter((item) => item.uid === cartItemuid);
      } else {
        return cartItemArr;
      }
    };

    const stockMinSaleQty = ref(1);
    const { getProductStock } = useFtProductStock();
    const fetchProductStock = async (product_uid) => {
      const { data } = await getProductStock(product_uid);
      stockMinSaleQty.value = data.getProductStock?.min_sale_qty ?? 1;
    };
    onBeforeMount(async () => {
      await fetchProductStock(props.product.uid);
      if (stockMinSaleQty.value > 1) {
        notifytype.value = 'info';
        productAlertMsg.value = 'This item has a Minimum Purchase Quantity Requirement of ' + stockMinSaleQty.value + '.';
      }
    });

    // Update Product Qty
    const updateProductQty = async (product, action) => {
      triggerAddRemoveCartTrack.value = true;
      showAddToCartError.value = false; // hide add to cart error
      if(apiState.getCartId()){
        cartItem.value = await getCartItem(product);
      }
      const requestQty = action === 'add' ? productqtytotal.value + 1 : productqtytotal.value - 1;  
      if (requestQty < stockMinSaleQty.value) {
        await removeItem({ product:cartItem.value[0] });
        cartItem.value = [];
      } else {
        if (requestQty == 0) {
          await removeItem({ product:cartItem.value[0] });
          cartItem.value = [];
        } else {
          await updateItemQty({ product:cartItem.value[0], quantity: requestQty});
        }
      }
      emit('openATCModal',action);
    };

    // Product Alert Subscription
    const notifytype = ref('');
    const productAlertMsg = ref('');
    const customerStore = useCustomerStore();
    const { user, load: loadUser } = useUser();
    const { saveInStockProductAlert } = useFtSaveInStockProductAlert();
    const productAlertClick = async () => {
      if (apiState.getCustomerToken()) {
        if (user.value === null) {
          await loadUser();
        }
        const { data } = await saveInStockProductAlert(props.product.uid);
        if (!data.saveInStockProductAlert.error) {
          notifytype.value = 'info';
          productAlertMsg.value = 'Alert subscription has been saved.';
        }
      } else {
        await customerStore.setTriggerLoginModal(true);
      }
    };

    // Bundle
    const selectedOptions = ref({});
    const multiSelectedOption = ref([]);
    const bundleProduct = computed(() => getBundleProducts(props.product));
    selectedOptions.value = bundleProductInitialSelector(bundleProduct.value);

    const canChangeQuantity = (bundle) => {
      const selectedOption = bundle.options.find(
        (o) => o.uid === selectedOptions.value[bundle.uid][0]?.uid,
      );
      return selectedOption?.can_change_quantity || false;
    };

    const price = computed(()=>Object.keys(selectedOptions.value).reduce((s, k)=> s
    + selectedOptions?.value[k]?.reduce((acc, curr)=> acc + (curr.price * curr.quantity), 0)
    ,0
    ));

    watch(
      computed(() => selectedOptions.value),
      () => {
        emit('update-price', parseFloat(price.value).toFixed(2));
      },
      {deep: true, immediate: true},
    );

    watch(
      () => bundleProduct.value,
      () => {
        if (Object.keys(selectedOptions.value).length == 0) {
          selectedOptions.value = bundleProductInitialSelector(bundleProduct.value);
        }
      },
    );

    const updSelectedOptionPrice = (bundleUid, optionUid) => {
      cartItem.value = [];
      const option = bundleProduct.value.filter((bundle)=>bundle.uid == bundleUid)[0].options.filter((option)=>option.uid == optionUid)[0]; 
      selectedOptions.value[bundleUid][0].price = option?.price || 0;
      selectedOptions.value[bundleUid][0].quantity = option?.quantity || 1;
    };

    const updMultiSelectedOptionPrice = (bundleUid, bundleOptions, selectedOptionArr) => {
      cartItem.value = [];
      const result = [];
      /**  
        * need to sort again the checkbox value because it will based on which checkbox selected first to have different queue value,
        * and it will causes 2 cart items with the same checkbox option but different queue, so need to sort it to have 2 cart items combine to 1.
      */
      selectedOptionArr.sort().forEach(selectedOption => {
        const option = bundleOptions.find((o) => o.uid == selectedOption);
        result.push({
          uid: option.uid,
          quantity: option.quantity || 1,
          price: option.price,
        });
      });
      selectedOptions.value[bundleUid] = result;
    };

    return {
      qty,
      getProductSwatchData,
      addItem,
      isCartLoading,
      canAddToCart,
      addToCartError,
      addToCart,
      acknowledgementCheckedFlag,
      acknowledgementError,
      productqtytotal,
      updateProductQty,
      showAddToCartError,
      isSaleable,
      productAlertClick,
      productAlertMsg,
      notifytype,
      bundleProduct,
      getProductName,
      getProductPrice,
      selectedOptions,
      canChangeQuantity,
      updSelectedOptionPrice,
      multiSelectedOption,
      updMultiSelectedOptionPrice,
      cart,
      triggerAddRemoveCartTrack,
      activecategory,
      currentCustomOption,
    }
  }
});
