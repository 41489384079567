var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product_custom-option"},_vm._l((_vm.product.options),function(ref){
var title = ref.title;
var radio_option = ref.radio_option;
var drop_down_option = ref.drop_down_option;
var checkbox_option = ref.checkbox_option;
var option_id = ref.option_id;
return (_vm.product.options)?_c('div',{staticClass:"product-selection"},[_c('label',{staticClass:"product-selection-label"},[_vm._v(_vm._s(title + ':'))]),_vm._v(" "),_vm._l((radio_option),function(ref){
var option_type_id = ref.option_type_id;
var title = ref.title;
var __typename = ref.__typename;
var price = ref.price;
return (radio_option)?_c('SfRadio',{key:(__typename + "-" + option_type_id),attrs:{"value":("/" + option_type_id),"label":price > 0 ? (title + " + " + (_vm.$fc(price))) : title,"id":option_type_id.toString(),"name":option_type_id.toString()},on:{"change":_vm.addSelectedOptionsToArray},model:{value:(_vm.radio_custom_option),callback:function ($$v) {_vm.radio_custom_option=$$v},expression:"radio_custom_option"}}):_vm._e()}),_vm._v(" "),_vm._l((checkbox_option),function(ref){
var option_type_id = ref.option_type_id;
var title = ref.title;
var __typename = ref.__typename;
var price = ref.price;
return (checkbox_option)?_c('SfCheckbox',{key:(__typename + "-" + option_type_id),attrs:{"value":("/" + option_type_id),"label":price > 0 ? (title + " + " + (_vm.$fc(price))) : title,"id":option_type_id.toString(),"name":option_type_id.toString()},on:{"change":_vm.addSelectedOptionsToArray},model:{value:(_vm.checkbox_custom_option),callback:function ($$v) {_vm.checkbox_custom_option=$$v},expression:"checkbox_custom_option"}}):_vm._e()}),_vm._v(" "),(drop_down_option)?_c('FtSelect',{key:option_id,staticClass:"product-selection-list",attrs:{"name":option_id,"id":option_id,"emptyLabel":_vm.$t('Select an option')},on:{"input":_vm.addSelectedOptionsToArray},model:{value:(_vm.dropdown_custom_option[option_id]),callback:function ($$v) {_vm.$set(_vm.dropdown_custom_option, option_id, $$v)},expression:"dropdown_custom_option[option_id]"}},_vm._l((drop_down_option),function(ref){
var option_type_id = ref.option_type_id;
var title = ref.title;
var __typename = ref.__typename;
var price = ref.price;
return _c('FtSelectOption',{key:(__typename + "-" + option_type_id),attrs:{"value":("/" + option_type_id),"label":price > 0 ? title + ' + ' +  _vm.$fc(price) : title,"selected":_vm.dropdown_custom_option[option_id] == ("/" + option_type_id)}},[_vm._v("\n        "+_vm._s(price > 0 ? title + ' + ' +  _vm.$fc(price) : title)+"\n      ")])}),1):_vm._e()],2):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }