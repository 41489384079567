





















import { ref, defineComponent, computed } from '@nuxtjs/composition-api';
import { SfChevron } from '@storefront-ui/vue';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'FortyTwoProductInfoDesc',
  components: {
    HTMLContent,
    SfChevron,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const productTabDiv = ref(null);
    const isProductTabOpen = ref(false);
    const productDescription = computed(() => props.product?.description?.html || '');

    const toogleProductTabOpen = () => {
      if (productTabDiv.value && productTabDiv.value.classList.contains('open-tab')) {
        isProductTabOpen.value = false;
        productTabDiv.value.classList.remove('open-tab');
      } else {
        isProductTabOpen.value = true;
        productTabDiv.value.classList.add('open-tab');
      }
    };

    return {
        isProductTabOpen,
        productDescription,
        toogleProductTabOpen,
        productTabDiv,
    }
  }
});
