




































































































import {
  SfLoader,
  SfRating,
  SfPrice,
  SfColor,
  SfSelect,
} from '@storefront-ui/vue';
import  SfGallery  from "~/components/customSFUI/vue/src/components/molecules/SfGallery/SfGallery.vue";
import {
  ref,
  computed,
  defineComponent,
  PropType, toRef,
  onMounted,
  onBeforeUnmount,
  useContext,
  watch,
} from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import {
  getSwatchData as getProductSwatchData,
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';
import {
  getTotalReviews,
  getAverageRating,
} from '~/modules/review/getters/reviewGetters';
import { usePageStore } from '~/stores/page';  
import { useReview } from '~/modules/review/composables/useReview';
import reviewGetters from '~/modules/review/getters/reviewGetters';
import ProductGuarantee from '~/modules/fortytwo/product/components/ProductGuarantee.vue';
import ProductHeader from '~/modules/fortytwo/product/components/ProductHeader.vue';
import ProductATCModal from '~/modules/fortytwo/product/components/ProductATCModal.vue';
import ProductYoutubeModal from '~/modules/fortytwo/product/components/ProductYoutubeModal.vue';
import BundleProductAddToCart from '~/modules/fortytwo/product/components/bundle/BundleProductAddToCart.vue';
import ProductReview from '~/modules/fortytwo/product/components/ProductReview.vue';
import ProductInfoDesc from '~/modules/fortytwo/product/components/ProductInfoDesc.vue';
import ProductInfoSpec from '~/modules/fortytwo/product/components/ProductInfoSpec.vue';
import ProductInfoDelivery from '~/modules/fortytwo/product/components/ProductInfoDelivery.vue';
import ProductInfoGuarantee from '~/modules/fortytwo/product/components/ProductInfoGuarantee.vue';
import ProductInfoQuestion from '~/modules/fortytwo/product/components/ProductInfoQuestion.vue';
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import 'photoswipe/dist/photoswipe.css';
import { useFtDynamicYieldList } from '~/dy/useFtDynamicYieldList';
import { useFtDynamicYieldGrid } from '~/dy/useFtDynamicYieldGrid';
import { useUiState } from "~/composables";

export default defineComponent({
  name: 'FortyTwoBundleProduct',
  components: {
    SfGallery,
    SfLoader,
    SfRating,
    SfPrice,
    SfColor,
    SfSelect,
    ProductGuarantee,
    ProductHeader,
    ProductATCModal,
    ProductYoutubeModal,
    BundleProductAddToCart,
    ProductReview,
    ProductInfoDesc,
    ProductInfoSpec,
    ProductInfoDelivery,
    ProductInfoGuarantee,
    ProductInfoQuestion,
    DYDataHandler,
    SkeletonLoader,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    routeData: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props) {
    const { app } = useContext();
    const product = toRef(props, 'product');
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const DYProduct = ref();
    const DYProduct2 = ref();
    // const { routeData } = usePageStore();
    const routeData = props.routeData;
    const { productGallery, imageSizes } = useProductGallery(product);
    const atcAction = ref('');
    const atcModalDisabledTimeoutId = ref(null);
    const atcModalDisplayTimeoutId = ref(null);
    const pswpLightbox = ref(null);
    const videoUrl = ref('');
    const { addtothecart,fetchDYLayout } = useFtDynamicYieldList();
    const { fetchDYGridLayout } = useFtDynamicYieldGrid();
    const { isProductImageModalOpen, toggleProductImageModal } = useUiState();
    
    const isMobile = ref(false);
    const updateScreenSize = () => isMobile.value = window.screen.width < 1024 || window.innerWidth < 1024 ? true : false;

    const fetchDYTypeCheck = async () => {
      const getSmartObj = document.querySelector('.dy_recommendations_mix');
      if(getSmartObj){
        const getSmartObjId = getSmartObj.id;
        const getSmartObjData = getSmartObj.getAttribute('data-id');
        await DYO.smartObject(getSmartObjData, {target: getSmartObjId, inline: true});
      }
    }

    onMounted(async () => {
      if(!pswpLightbox.value) {
        pswpLightbox.value = new PhotoSwipeLightbox({
          gallery: '.product__gallery ul.glide__slides',
          children: '.sf-gallery__stage ul.glide__slides li a',
          mainClass: 'pswp--custom-bg',
          bgOpacity: 1,
          showHideAnimationType: 'none',
          pswpModule: () => import('photoswipe')
        });
        pswpLightbox.value.on('uiRegister', function() {
          pswpLightbox.value.pswp.ui.registerElement({
            name: 'bulletsIndicator',
            className: 'pswp__bullets-indicator',
            appendTo: 'wrapper',
            onInit: (el, pswp) => {
              const bullets = [];
              let bullet;
              let prevIndex = -1;

              for (let i = 0; i < pswp.getNumItems(); i++) {
                bullet = document.createElement('div');
                bullet.className = 'pswp__bullet';
                bullet.onclick = (e) => {
                  pswp.goTo(bullets.indexOf(e.target));
                };
                el.appendChild(bullet);
                bullets.push(bullet);
              }

              pswp.on('change', (a,) => {
                if (prevIndex >= 0) {
                  bullets[prevIndex].classList.remove('pswp__bullet--active');
                }
                bullets[pswp.currIndex].classList.add('pswp__bullet--active');
                prevIndex = pswp.currIndex;
              });
            }
          });
        });
        pswpLightbox.value.init();
      }
      
      if(process.client){
        fetchDYData();
      }
      updateScreenSize();
      if(app.$device.isDesktop) {
        window.addEventListener("resize", updateScreenSize);
      }

      if (process.client) {
        window.addtothecart = addtothecart;
        window.fetchDYGridLayout = fetchDYGridLayout;
        window.fetchDYLayout = fetchDYLayout;
      }
      DYO.waitForElement('.dy_recommendations_mix', function() {
        fetchDYTypeCheck();
      }, 1, 200, 15);

      // facabook pixel and conversions api
      if (productPrices.value?.regular) {
        await sendFacebookPixel(
          'ViewContent', 
          routeData.sku, 
          {
            'id': props.product?.sku ?? routeData.sku,
            'quantity': 1,
            'item_price': productPrices.value?.regular ?? 0
          }
        );
        await sendFacebookPixel('PageView', '');
      }
    });

    onBeforeUnmount(()=>{
      if(pswpLightbox.value) {
        pswpLightbox.value.destroy();
        pswpLightbox.value = null;
      }
      if(app.$device.isDesktop) {
        window.removeEventListener("resize", updateScreenSize);
      }
      if (atcModalDisabledTimeoutId.value) {
        clearTimeout(atcModalDisabledTimeoutId.value);
      }
      if (atcModalDisplayTimeoutId.value) {
        clearTimeout(atcModalDisplayTimeoutId.value);
      }
    });
          
    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
    });

    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));

    const reviews = ref(null);
    const { search: searchReviews } = useReview();
    const fetchReviews = async (query = getBaseSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews) ? productReviews[0] : productReviews;
      reviews.value = reviewGetters.getItems(baseReviews);
    };
    const newQuery = getBaseSearchQuery();
    fetchReviews(newQuery);

    // Get Delivery Info from child
    const productDelivery : any = ref({});
    const standardDeliveryDateDisplay = ref<String>('');
    const updDeliveryInfo = ({paramProductDelivery, paramStandardDeliveryDateDisplay}) => {
      productDelivery.value = paramProductDelivery;
      standardDeliveryDateDisplay.value = paramStandardDeliveryDateDisplay;
    };

    // Bundle
    const productPrices = computed(() => getProductPrice(props.product));
    const bundleOptionPrice = ref(0);

    // Custom Option
    const customOptionPrice = 0;

    // Prompt Add To Cart Modal
    const openATCModal = (action) => {
      atcAction.value = action;
      const atcModal = document.getElementById("addToCartModal");
      atcModal.style.display = "block";
      atcModal.className="atcmodal atcmodal-enabled";
      atcModalDisabledTimeoutId.value = setTimeout(()=> atcModal.className="atcmodal atcmodal-disabled", 3000);
      atcModalDisplayTimeoutId.value = setTimeout(()=> atcModal.style.display = "none", 6000);
    };

    const fetchDYData = async () => {
      const selectType = ["Bottom Recommendation - Similar Products","Bottom Recommendation - Cross-Selling"];
      const productsku = [routeData.sku];
      const currentURL = window.location.href;
      await apidycall(selectType,"PRODUCT","Choose",productsku,[],currentURL,false);
      for (let campaignIndex = 0; campaignIndex < dyresultdata.value.length; campaignIndex++) {
        if(dyresultdata.value[campaignIndex].campaigntype == "Bottom Recommendation - Similar Products"){
          DYProduct.value = { 
            variationsId : dyresultdata.value[campaignIndex].variationsId,
            data : dyresultdata.value[campaignIndex].data, 
            decisionId : dyresultdata.value[campaignIndex].decisionId, 
            title:dyresultdata.value[campaignIndex].title, 
            itemsku:dyresultdata.value[campaignIndex].itemsku
          };
        }else if(dyresultdata.value[campaignIndex].campaigntype == "Bottom Recommendation - Cross-Selling"){
          DYProduct2.value = { 
            variationsId : dyresultdata.value[campaignIndex].variationsId,
            data : dyresultdata.value[campaignIndex].data, 
            decisionId : dyresultdata.value[campaignIndex].decisionId, 
            title:dyresultdata.value[campaignIndex].title, 
            itemsku:dyresultdata.value[campaignIndex].itemsku
          };
        }
      }
    };

    const playVideo = (videoURL) => {
      videoUrl.value = videoURL;
      toggleProductImageModal();
    }

    watch(
      () => productPrices.value.regular,
      async (newProductPrice, oldProductPrice) => {
        if (newProductPrice != null && newProductPrice != 0 && oldProductPrice == 0){
          // facabook pixel and conversions api
          await sendFacebookPixel(
            'ViewContent', 
            routeData.sku, 
            {
              'id': props.product?.sku ?? routeData.sku,
              'quantity': 1,
              'item_price': newProductPrice ?? 0
            }
          );
          await sendFacebookPixel('PageView', '');
        }
      }   
    );
    
    return {
      DYProduct,
      DYProduct2,
      isMobile,
      productGallery,
      imageSizes,
      getProductName,
      getProductSwatchData,
      totalReviews,
      averageRating,  
      reviews,
      productDelivery,
      standardDeliveryDateDisplay,
      updDeliveryInfo,
      productPrices,
      bundleOptionPrice,
      customOptionPrice,
      atcAction,
      openATCModal,
      isDYProductReady: computed(() => DYProduct.value?.data?.length > 0 ? true:false),
      isDYProduct2Ready: computed(() => DYProduct2.value?.data?.length > 0 ? true:false),
      isProductImageModalOpen,
      toggleProductImageModal,
      playVideo,
      videoUrl,
    };
  },
});
