import { useApi } from '~/composables/useApi';

export const useFtProductStock = () => {
  const { query } = useApi();

  const getProductStock:any = async (product_uid) =>{
    const myGraphqlQuery = `
      query getProductStock($product_id: String!){
        getProductStock(product_id: $product_id){
            min_sale_qty,
            only_x_left_in_stock
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery, {product_id: product_uid});

    return {
      data,
    };
  }
  
  return {
    getProductStock,
  }
}


export default useFtProductStock;