













































import {
  defineComponent,
  onMounted
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';

export default defineComponent({
  name: 'ProductGrabPayPriceDividerModal',
  props: {
      installmentPrice: {
      type: Number,
      default: 0,
      },
  },
  setup() {
    const { isGrabPayPriceDividerModalOpen, toggleGrabPayPriceDividerModal} = useUiState();

    onMounted(async () => {
      if (isGrabPayPriceDividerModalOpen.value) {
          toggleGrabPayPriceDividerModal();
      }
    })

    return {
      isGrabPayPriceDividerModalOpen
    };
  },
});
