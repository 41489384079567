

























import { defineComponent, ref, computed, watch } from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import useFtDelivery from '~/modules/fortytwo/delivery/composables/useFtDelivery';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import ProductDeliveryInfo from '~/modules/fortytwo/delivery/components/product/DefaultProductDeliveryInfo.vue';

export default defineComponent({
name: "BundleProductDeliveryInfo",
components: {
  SkeletonLoader,
  ProductDeliveryInfo,
},
props: ["product", "selectedOptions", "qty"],
setup(props, { emit }) {
  const productDelivery : any = ref({})
  const isLoadingDelivery = ref(true);
  const isSaleable = computed(() : Boolean => props.product?.stock_status == 'IN_STOCK' ? true: false); 
  const { getProductDelivery } = useFtDelivery();
  const standardDeliveryGroupName = ref<String>('');
  const standardDeliveryDateDisplay = ref<String>('');
  const expressDeliveryGroupName = ref<String | boolean>('');
  const expressDeliveryDayDisplay = ref<String>('');
  const expressDeliveryEarliestTimeslot = ref<String>('');
  const canExpress = ref<Boolean>(false);
  const product_qty = computed(() => props.qty ? props.qty : 1);

  const fetchProductDeliveryData = async (product) => {
    const { 
      data:deliveryData, 
      getStandardDeliveryGroupName, 
      getStandardDeliveryDateDisplay, 
      getCanExpress,
      getExpressDeliveryGroupName,
      getExpressDeliveryDayDisplay,
      getExpressDeliveryEaliestTimeslot,
      loading
    } = await getProductDelivery(product);
    productDelivery.value = merge(productDelivery.value, deliveryData);
    isLoadingDelivery.value = loading.value ?? true;

    standardDeliveryGroupName.value = getStandardDeliveryGroupName();
    standardDeliveryDateDisplay.value = getStandardDeliveryDateDisplay();

    canExpress.value = getCanExpress();
    expressDeliveryGroupName.value = getExpressDeliveryGroupName();
    expressDeliveryDayDisplay.value = getExpressDeliveryDayDisplay();
    expressDeliveryEarliestTimeslot.value = getExpressDeliveryEaliestTimeslot();
  };

  const hasSelectedOption = computed(() => {
    const selected = ref(false);
    const options = Object.keys( props.selectedOptions );
    options.forEach( function ( options_key ) {
      props.selectedOptions[options_key].forEach( function ( option ) {
        if (option?.uid && option?.uid!=""){
          selected.value = true;
        }
      })
    })
    return selected.value;
  }); 
  

  watch(
    () => props.selectedOptions,
      async (newVal) => {
        if (hasSelectedOption.value){
          await fetchProductDeliveryData({uid:props.product.uid, type: props.product.__typename, selected_options: props.selectedOptions, qty: product_qty.value})
          emit('deliveryInfo', { paramProductDelivery:productDelivery.value, paramStandardDeliveryDateDisplay:standardDeliveryDateDisplay.value });
        }
        
          // await fetchProductDeliveryData({uid:props.product.uid, type: props.product.__typename, selected_options: props.selectedOptions})
          // emit('deliveryInfo', { paramProductDelivery:productDelivery.value, paramStandardDeliveryDateDisplay:standardDeliveryDateDisplay.value });
      },
      { deep: true, immediate: true },
  );
  watch(
      () => product_qty.value,
         async (newQty, oldQty) => {
          if (oldQty != newQty){
            await fetchProductDeliveryData({uid: props.product.uid, selected_options: props.selectedOptions, type: props.product.__typename, qty: newQty});
            emit('deliveryInfo', { paramProductDelivery:productDelivery.value, paramStandardDeliveryDateDisplay:standardDeliveryDateDisplay.value });
          }
        }, 
    );

  return {
      productDelivery,
      isLoadingDelivery,
      isSaleable,
      standardDeliveryDateDisplay,
      standardDeliveryGroupName,
      canExpress,
      expressDeliveryGroupName,
      expressDeliveryDayDisplay,
      expressDeliveryEarliestTimeslot,
      hasSelectedOption
  };
},
});
