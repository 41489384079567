





















































































































































































































import { ref, defineComponent, computed, onMounted, watch } from '@nuxtjs/composition-api';
import { SfChevron } from '@storefront-ui/vue';
import moment from 'moment';

export default defineComponent({
  name: 'FortyTwoProductInfoDelivery',
  components: {
    SfChevron,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    productDelivery: {
      type: Object,
      default: null,
    },
    standardDeliveryDateDisplay: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isProductTabOpen = ref(false);
    const nowDateTime = moment().format("DD/MM/YYYY \@ hh:mma");
    const isAllVarianceSelected = computed(() : Boolean => 
        props.product?.__typename == 'ConfigurableProduct' 
        ? props.product?.configurable_product_options_selection?.variant ? true: false 
        : props.product?.stock_status == 'IN_STOCK' ? true: false
    ); 
    const isPostal =  computed(() => props.productDelivery?.getProductDeliveryInfo?.postal || false);
    const showStandardDeliveryDate =  computed(() => props.standardDeliveryDateDisplay ? props.standardDeliveryDateDisplay.includes('1970') ? false : true : false);
    const fulfilmentAgencyName =  computed(() => {
        if (isPostal.value){
            return props.productDelivery?.getProductDeliveryInfo?.postal?.fulfillment_agency_name
        }
        else{
            return props.productDelivery?.getProductDeliveryInfo?.standard?.fulfillment_agency_name || '';
        }
    });
    const deliveryFeeType =  computed(() => props.productDelivery?.getProductDeliveryInfo?.standard?.delivery_fee_type || '');
    const freeDeliveryMinSubtotal =  computed(() => props.productDelivery?.getProductDeliveryInfo?.standard?.free_delivery_min_subtotal || 0);
    const changeViewMore = (event) => {
        if (event.currentTarget.classList.contains('less')) {
            event.currentTarget.innerHTML = 'View More <span class="sf-chevron"><span class="sf-chevron__bar sf-chevron__bar--left"></span><span class="sf-chevron__bar sf-chevron__bar--right"></span></span>';
            event.currentTarget.classList.remove('less');
            event.currentTarget.previousElementSibling.style.maxHeight = 0;
        } else {
            event.currentTarget.innerHTML = 'View Less <span class="sf-chevron sf-chevron--top"><span class="sf-chevron__bar sf-chevron__bar--left"></span><span class="sf-chevron__bar sf-chevron__bar--right"></span></span>';
            event.currentTarget.classList.add('less');
            event.currentTarget.previousElementSibling.style.maxHeight = '1000px';
        }
    };

    onMounted(() => {
        isProductTabOpen.value = props.isMobile ? false : true;
    });

    watch(
      () => props.isMobile,
      (newIsMobile) => {
        if (newIsMobile) {
            isProductTabOpen.value = newIsMobile ? false : true;
        }
      }
    );

    return {
        isProductTabOpen,
        nowDateTime,
        isAllVarianceSelected,
        isPostal,
        showStandardDeliveryDate,
        fulfilmentAgencyName,
        deliveryFeeType,
        freeDeliveryMinSubtotal,
        changeViewMore,
    }
  }
});
