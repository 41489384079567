

























































































































import { ref, defineComponent, computed, onMounted, watch } from '@nuxtjs/composition-api';
import { SfChevron } from '@storefront-ui/vue';

export default defineComponent({
  name: 'FortyTwoProductInfoGuarantee',
  components: {
    SfChevron,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isProductTabOpen = ref(false);
    const refundable = computed(() => props.product?.refundable ?? '');
    const changeViewMore = (event) => {
        if (event.currentTarget.classList.contains('less')) {
            event.currentTarget.innerHTML = 'View More <span class="sf-chevron"><span class="sf-chevron__bar sf-chevron__bar--left"></span><span class="sf-chevron__bar sf-chevron__bar--right"></span></span>';
            event.currentTarget.classList.remove('less');
            event.currentTarget.previousElementSibling.style.maxHeight = 0;
        } else {
            event.currentTarget.innerHTML = 'View Less <span class="sf-chevron sf-chevron--top"><span class="sf-chevron__bar sf-chevron__bar--left"></span><span class="sf-chevron__bar sf-chevron__bar--right"></span></span>';
            event.currentTarget.classList.add('less');
            event.currentTarget.previousElementSibling.style.maxHeight = '1000px';
        }
    };

    onMounted(() => {
        isProductTabOpen.value = props.isMobile ? false : true;
    });

    watch(
    () => props.isMobile,
    (newIsMobile) => {
        if (newIsMobile) {
            isProductTabOpen.value = newIsMobile ? false : true;
        }
    }
    );

    return {
        isProductTabOpen,
        refundable,
        changeViewMore,
    }
  }
});
