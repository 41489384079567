

















































































import {
  computed,
  defineComponent,
  PropType,
  useContext,
} from '@nuxtjs/composition-api';
import {
  SfHeading,
  SfRating,
  SfIcon,
} from '@storefront-ui/vue';
import { getName as getProductName, getPrice as getProductPrice } from '~/modules/catalog/product/getters/productGetters';
import { getConfigurableProductPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductPriceCommand';
import { getConfigurableProductSpecialPriceCommand } from '~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import ProductGrabWidget from '~/modules/fortytwo/product/components/ProductGrabWidget.vue';
import ProductPrice from '~/modules/fortytwo/product/components/ProductPrice.vue';
import { useUiNotification } from "~/composables";
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import FtRating from "~/components/customSFUI/vue/src/components/atoms/FtRating/FtRating.vue";

export default defineComponent({
  name: 'FortyTwoProductHeader',
  components: {
    SfHeading,
    SfRating,
    SfIcon,
    ProductPrice,
    SkeletonLoader,
    ProductGrabWidget,
    FtRating,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    totalReviews: {
      type: Number,
      default: null,
    },
    averageRating: {
      type: Number,
      default: null,
    },
    bundleOptionPrice: {
      type: Number,
      default: 0,
    },
    customOptionPrice: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const isPriceLoading = computed(() => props.product?.price_range?.minimum_price?.regular_price?.value >= 0 ? false : true );
    const bundleMainPrice = computed(() => props.product?.bundle_main_price?.price ?? 0);
    const bundleMainSpecialPrice = computed(() => props.product?.bundle_main_price?.final_price < props.product?.bundle_main_price?.price 
    ? props.product?.bundle_main_price?.final_price : null);

    const productPrice = computed(() => props.product.__typename != 'ConfigurableProduct' 
    ? (props.product.__typename == 'BundleProduct' ? bundleMainPrice.value : getProductPrice(props.product).regular) + props.bundleOptionPrice + props.customOptionPrice 
    : getConfigurableProductPriceCommand(props.product) + props.customOptionPrice);

    const productSpecialPrice = computed(() => props.product.__typename != 'ConfigurableProduct' 
    ? ( 
      props.product.__typename == 'BundleProduct'
      ? bundleMainSpecialPrice.value && bundleMainSpecialPrice.value + props.bundleOptionPrice + props.customOptionPrice
      : getProductPrice(props.product).special && getProductPrice(props.product).special + props.bundleOptionPrice + props.customOptionPrice
    )
    : getConfigurableProductSpecialPriceCommand(props.product) && getConfigurableProductSpecialPriceCommand(props.product) + props.customOptionPrice);

    const lowPrice = computed(() => props.product?.price_range?.minimum_price?.regular_price?.value ?? 0);
    const highPrice = computed(() => props.product?.price_range?.maximum_price?.regular_price?.value ?? 0);
    const priceCurrency = computed(() => props.product?.price_range?.maximum_price?.regular_price?.currency ?? '');

    // warranty START
    const warranty = computed(() => props.product?.product_warranty_label ?? '');
    const warrantyNum = computed(() => warranty.value.includes('-') ? warranty.value.slice(0, warranty.value.indexOf('-')) : 0);
    const warrantyPeriod = computed(() => warranty.value.includes('Year') ? 'Year Warranty' : 'Month Warranty');
    // warranty END
    const { isInWishlist, addItem: addItemToWishlistBase, removeItem: removeItemFromWishlist } = useWishlist();
    const { app: { i18n, $vsf } } = useContext();
    const { send: sendNotification } = useUiNotification();

    const reviewTotalScore = computed(() => props.product?.getreviewscore?.review_total_score ?? 0);
    const reviewTotalScoreDisplay = computed(() => (parseFloat(props.product?.getreviewscore?.review_total_score) % 1 === 0 ? parseFloat(props.product?.getreviewscore?.review_total_score).toFixed(1) : parseFloat(props.product?.getreviewscore?.review_total_score).toFixed(2)) ?? 0);
    const viewAllReviewlink = computed(() => props.product?.sku ? '/review/product/list/' + Buffer.from(props.product?.sku).toString('base64') : '');
    const qtySold = computed(() => {
      const qty = Number(props.product.total_qty_sold);
        
      if (qty >= 1 && qty <= 999) {
        return `${qty}`;
      } else if (qty >= 1000 && qty <= 9999) {
        const formatted = (qty / 1000).toFixed(1);
        return `${formatted}k+`;
      } else if (qty >= 10000) {
        const formatted = Math.floor(qty / 1000);
        return `${formatted}k+`;
      } else {
        return '0';
      }
    });
    const isShowQtySold = computed(() => props.product?.total_qty_sold > 0);

    const wishlistClick = async (product) => {
      if ($vsf.$magento.config.state.getCustomerToken()) {
        let message = '';

        if (isInWishlist(product)) {
          await removeItemFromWishlist(product);
          message = i18n.t('Item removed from wishlist successfully.') as string;
        } else {
          await addItemToWishlistBase(product);
          message = i18n.t('Item added to wishlist successfully.') as string;
        }

        sendNotification({
          id: Symbol('wishlist_updated'),
          message: message,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Wishlist Notification',
        });

      } else {
        sendNotification({
          id: Symbol('wishlist_updated'),
          message: i18n.t('Oh no, we have a problem here! Remember to log in to add this item to your wishlist.') as string,
          type: 'warning',
          icon: '',
          persist: false,
          title: 'Wishlist Notification',
        });
      }
    };

    return {
        getProductName,
        productPrice,
        productSpecialPrice,
        warranty,
        warrantyNum,
        warrantyPeriod,
        isInWishlist: computed(() => isInWishlist({ product: props.product })),
        reviewTotalScore,
        reviewTotalScoreDisplay,
        viewAllReviewlink,
        wishlistClick,
        isPriceLoading,
        lowPrice,
        highPrice,
        priceCurrency,
        qtySold,
        isShowQtySold,
    }
  }
});
