























import { defineComponent, useRouter, useContext, } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FortyTwoProductATCModal',
  props: ["productName", "atcAction"],
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const close = () => document.getElementById("addToCartModal").className="atcmodal atcmodal-disabled";
    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    return {
        close,
        goToCart,
    }
  }
});
