







































import { computed, defineComponent, watch, ref, onMounted } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FortyTwoProductGuarantee',
  props: ["product"],
  setup(props) {
    let guarantees = ref([]);
    const productGuarantees = computed(() => props.product?.product_guarantees_label?.split(',') ?? '');
    const assemblyType = computed(() => props.product?.product_assembly_type_label ?? '');
    const refundable = computed(() => props.product?.refundable ?? '');
    const isHaveGuarantees = computed(()=> guarantees.value.length > 0 ? true : false);

    const getGuarantees = (productGuarantees, assemblyType, refundable) => {
      guarantees.value = [];
      if ((productGuarantees.length > 0 && refundable) || assemblyType != '') {
        guarantees.value = [];
        if(productGuarantees.length > 0 && refundable && !productGuarantees.includes('assembly')) {
          guarantees.value = [...productGuarantees];
        }
        if (assemblyType) {
          guarantees.value.push(assemblyType);
        }
        guarantees.value.push('600k+ Satisfied Customers');
        guarantees.value.push('0% Instalment Plans Available');
      }
    };

    watch(
      () => [productGuarantees.value, assemblyType.value, refundable.value],
      async ([productGuarantees, assemblyType, refundable]) => {
        await getGuarantees(productGuarantees, assemblyType, refundable);
      }   
    );

    onMounted(async () => {
      await Promise.all([getGuarantees(productGuarantees.value, assemblyType.value, refundable.value)]);
    });

    return {
      guarantees,
      isHaveGuarantees,
    }
  },
});
