



















import { defineComponent, ref, onMounted} from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { SfCheckbox} from '@storefront-ui/vue';
import useFtProductAcknowledgment from '~/modules/fortytwo/acknowledgement/composables/useFtProductAcknowledgment';

export default defineComponent({
    name: "ProductAcknowledgement",
    components: {
        SfCheckbox,
    },
    props: ["productUID"],
    setup(props,{ emit }) {
        const checkbox_acknowledgment:any = ref([]);
        const productAck:any = ref([]);
        const { getProductAcknowledgment } = useFtProductAcknowledgment();
        const fetchProductAcknowledgementExtendedData = async () => {
          const { data } = await getProductAcknowledgment(props.productUID);
          productAck.value = merge({}, productAck.value, data);
          ackValidation();
        };
  
        const ackValidation = async () => {
            if (checkbox_acknowledgment.value.length == productAck.value?.productacknowledgment?.length) {
                emit('update-acknowledgement', true);  
            } else {
                emit('update-acknowledgement', false);
            }
        };
  
        onMounted(async () => {
          await Promise.all([fetchProductAcknowledgementExtendedData()]);
        });
  
        return {
          productAck,
          checkbox_acknowledgment,
          ackValidation
        };
    },
});
