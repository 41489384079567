














import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api';
import Swal from "sweetalert2";

export default defineComponent({
  name: 'FortyTwoProductBanner',
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isQoltured = computed(() => props.product?.categories?.findIndex(category => category.name === 'Qoltured') >= 0);
    const isLinsy = computed(() => props.product?.categories?.findIndex(category => category.name === 'LINSY by FortyTwo') >= 0);

    onMounted(async () => {
      if (process.client) {
        window.Swal = Swal;
      }
    });

    return {
      isQoltured,
      isLinsy,
    }
  }
});
