





























import {
  ref,
  defineComponent,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfIcon,
} from '@storefront-ui/vue';
import FtModal from "~/components/customSFUI/vue/src/components/molecules/FtModal/FtModal.vue";
import { useUiState } from '~/composables/useUiState';
import 'vue-plyr/dist/vue-plyr.css';

export default defineComponent({
  name: 'FortyTwoProductYoutubeModal',
  components: {
    SfModal,
    SfIcon,
    FtModal,
  },
  props: {
    videoUrl: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { isProductImageModalOpen } = useUiState();
    const videoId = ref(null);
    const videoType = ref('');

    const videoSrc = computed(() => 
    videoType.value === 'youtube' 
    ? ('https://www.youtube.com/embed/'+ videoId.value + '?iv_load_policy=3&rel=0&autoplay=1&playsinline=1&enablejsapi=1&loop=1&origin='+ window.location.origin + '&playlist='+ videoId.value)
    : ('https://player.vimeo.com/video/'+ videoId.value + '?autoplay=1&loop=1&playsinline=1&loop=0&byline=0&portrait=0&title=0&speed=0&transparent=0&pip=0&dnt=1'));

    const getVideoIdfromURL = (url) => {
      const urlObj = new URL(url);

      // Check if the URL is a Vimeo URL like https://vimeo.com/{videoId}
      if (urlObj.hostname === 'vimeo.com') {
        videoType.value = 'vimeo';
        return urlObj.pathname.split('/')[1];
      }

      // Check if the URL is a YouTube Shorts URL like https://www.youtube.com/shorts/{videoId}
      if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname.startsWith('/shorts/')) {
        videoType.value = 'youtube';
        return urlObj.pathname.split('/')[2];
      }

      // Check if the URL is a shortened YouTube URL like https://youtu.be/{videoId}
      if (urlObj.hostname === 'youtu.be') {
        videoType.value = 'youtube';
        return urlObj.pathname.split('/')[1];
      }

      // Check if the URL is a regular YouTube URL like https://www.youtube.com/watch?v={videoId}
      if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
        videoType.value = 'youtube';
        return urlObj.searchParams.get('v');
      }

      return null;
    };

    onMounted(() => {
      videoId.value = getVideoIdfromURL(props.videoUrl);
    });

    return {
      isProductImageModalOpen,
      videoSrc,
      videoId,
      videoType,
    };
  },
});
