



















import { defineComponent, ref, onMounted, watch } from '@nuxtjs/composition-api';
import ProductGrabPayPriceDividerModal from '~/modules/fortytwo/product/components/ProductGrabPayPriceDividerModal.vue';
import { useUiState } from '~/composables/useUiState';

export default defineComponent({
  name: 'FortytwoProductGrabWidget',
  components: {
    ProductGrabPayPriceDividerModal
  },
  props: {
    dataProductPrice: {
      type: Number,
      default: 0,
    },
  },
  setup(props,{emit}) {
    const { isGrabPayPriceDividerModalOpen, toggleGrabPayPriceDividerModal} = useUiState();
    const installmentPrice:any = ref(0);
    const priceDivider = () => {
        if (props.dataProductPrice >= 10 && props.dataProductPrice <= 5000){
            installmentPrice.value = (props.dataProductPrice / 4).toFixed(2);
        } else {
          installmentPrice.value = 0;
        }
    };

    watch( () => props.dataProductPrice,
        (newVal) => {
         priceDivider();
        },
      );

    onMounted(async () => {
        await priceDivider();
    });

    return{
        isGrabPayPriceDividerModalOpen,
        toggleGrabPayPriceDividerModal,
        installmentPrice,
        priceDivider
    }
  }
});
