
































































































import {
  ref,
  defineComponent,
  computed,
  PropType,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import {
  SfRating,
  SfDivider,
} from '@storefront-ui/vue';
import {
  getReviewAuthor,
  getReviewDate,
  getReviewMessage,
  getReviewRating,
} from '~/modules/review/getters/reviewGetters';
import moment from 'moment';
import { Product } from '~/modules/catalog/product/types';
import FtRating from "~/components/customSFUI/vue/src/components/atoms/FtRating/FtRating.vue";

export default defineComponent({
  name: 'FortyTwoProductReview',
  components: {
    SfRating,
    SfDivider,
    FtRating,
  },
  props: {
    reviews: {
      type: Array,
      default: null,
    },
    averageRating: {
      type: Number,
      default: 0,
    },
    totalReviews: {
      type: Number,
      default: null,
    },
    reviewScore: {
      type: Object,
    },
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
  },

  setup(props) {
    const { app } = useContext();
    const productTabDiv = ref(null);
    const isProductTabOpen = ref(true);
    // Only List out 5 reviews at product page
    const reviewList = computed(() => props.reviews?.slice(0, 5)); 
    const reviewTotalScore = computed(() => props.reviewScore?.review_total_score ?? 0);
    // Review Histogram
    const reviewChart = ref([]);
    reviewChart.value[0] = props.reviewScore?.review_chart_score?.one ?? 0;
    reviewChart.value[1] = props.reviewScore?.review_chart_score?.two ?? 0;
    reviewChart.value[2] = props.reviewScore?.review_chart_score?.three ?? 0;
    reviewChart.value[3] = props.reviewScore?.review_chart_score?.four ?? 0;
    reviewChart.value[4] = props.reviewScore?.review_chart_score?.five ?? 0;

    const viewAllReviewlink = computed(() => props.product?.sku ? '/review/product/list/' + Buffer.from(props.product?.sku).toString('base64') : '');

    const toogleProductTabOpen = () => {
      if (productTabDiv.value && productTabDiv.value.classList.contains('open-tab')) {
        isProductTabOpen.value = false;
        productTabDiv.value.classList.remove('open-tab');
      } else {
        isProductTabOpen.value = true;
        productTabDiv.value.classList.add('open-tab');
      }
    };

    onMounted(() => {
      if(app.$device.isDesktop) {
        isProductTabOpen.value = false;
      }
    });

    return {
      isProductTabOpen,
      reviewList,
      reviewChart,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      getReviewRating,
      moment,
      reviewTotalScore,
      viewAllReviewlink,
      productTabDiv,
      toogleProductTabOpen,
    }
  },
  methods: {
    modifyName(fullName){
    // Split the name into parts
    const nameParts = fullName.split(" ");

    // Initialize an empty array to store the modified name parts
    let modifiedNameParts = [];

    // Loop through each part of the name
    for (let i = 0; i < nameParts.length; i++) {
      if (i === 0) {
        // Keep the first name as it is
        modifiedNameParts.push(nameParts[i]);
      } else {
        // Add the first letter with a dot for middle and last names
        modifiedNameParts.push(nameParts[i].charAt(0) + ".");
      }
    }

    // Join the modified name parts into the final string
    const modifiedName = modifiedNameParts.join(" ");
    return modifiedName;
    }
  }
});
