<template>
  <div class="sf-gallery">
    <div class="sf-gallery__stage">
      <div ref="glide" class="glide"
      :class="{'loading': !glide}">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li
              v-for="(picture, index) in images"
              :key="'slide-' + index"
              class="glide__slide"
              @mouseover="startZoom(picture)"
              @mousemove="moveZoom($event, index)"
              @mouseout="removeZoom(index)"
            >
              <a
                :href="picture.original.url"
                :data-pswp-width="3000"
                :data-pswp-height="2500"
                target="_blank" 
                rel="noreferrer"
              >
              <slot
                name="big-image"
                v-bind="{
                  enableZoom,
                  picture,
                  index,
                  imageWidth,
                  imageHeight,
                  imageTag,
                  nuxtImgConfig,
                }"
              >
                <SfImage
                  ref="sfGalleryBigImage"
                  class="sf-gallery__big-image"
                  :class="{ 'sf-gallery__big-image--has-zoom': enableZoom }"
                  :src="picture.desktop.url"
                  :alt="picture.alt"
                  :placeholder="picture.placeholder"
                  :width="imageWidth"
                  :height="imageHeight"
                  :image-tag="imageTag"
                  :nuxt-img-config="nuxtImgConfig"
                  loading="eager"
                />
              </slot>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SkeletonLoader width="100%" class="gallery-main-image-skeleton" radius="30px" margin="0" v-if="!glide"/>
      <transition name="sf-fade">
        <div
          ref="outSide"
          :class="{
            'display-none':
              !outsideZoom || !isZoomStarted || (!outsideZoom && !enableZoom),
          }"
          :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
        >
          <slot
            name="outside-zoom"
            v-bind="{
              definedPicture,
              imageWidth,
              imageHeight,
              imageTag,
              nuxtImgConfig,
            }"
          >
            <SfImage
              ref="imgZoom"
              class="sf-gallery__zoom"
              :src="definedPicture.url"
              :width="imageWidth"
              :height="imageHeight"
              :lazy="false"
              :alt="definedPicture.alt"
              :placeholder="definedPicture.placeholder"
              :image-tag="imageTag"
              :nuxt-img-config="nuxtImgConfig"
            />
          </slot>
        </div>
      </transition>
      <div class="product-vid" v-if="definedPicture.video" @click="$emit('playVideo', definedPicture.video)">
        <span class="product-vid-btn"></span>
      </div>
      <ProductLabel :product="product" :imageSize="imageSizes.productCard" v-if="product.uid"/> 
    </div> 
    <div class="sf-gallery__thumbs">
      <slot name="thumbs" v-bind="{ images, active: activeIndex, go, updateActiveIndex }">
        <Flicking ref="sfGalleryImageRefresh" :options="{ align: 'prev', circular: false, horizontal: isMobile, moveType: 'freeScroll', bound: true }" :plugins="plugins" v-if="glide">
          <SfButton
            v-for="(image, index) in images"
            :key="'img-' + index"
            class="sf-button--pure sf-gallery__item"
            :class="{ 'sf-gallery__item--selected': index === activeIndex }"
            :aria-label="'Image ' + index"
            @click="go(index)"
          >
          <div class="product-vid" v-if="image.video.url" @click="$emit('playVideo', image.video.url)">
            <span class="product-vid-btn thumbs"></span>
          </div>
            <SfImage
              class="sf-gallery__thumb"
              :src="image.mobile.url"
              :alt="image.alt"
              :placeholder="image.placeholder"
              :width="thumbWidth"
              :height="thumbHeight"
              :image-tag="thumbImageTag"
              :nuxt-img-config="thumbNuxtImgConfig"
              loading="eager"
              placeholder="/custom/fortytwo-loading-icon.gif"
            />
          </SfButton>
          <span slot="viewport" class="flicking-arrow-prev is-circle"></span>
          <span slot="viewport" class="flicking-arrow-next is-circle"></span>
        </Flicking>
        <template v-else>
          <SkeletonLoader radius="8px" class="gallery-thumbnail-skeleton" v-for="n in 4" :key="n"/>
        </template>
      </slot>
    </div>
  </div>
</template>
<script>
import {
  ref,
  onDeactivated,
  onActivated,
  nextTick
} from '@nuxtjs/composition-api';
import Glide from "@glidejs/glide";
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import { Flicking } from "@egjs/vue-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import ProductLabel from '~/modules/fortytwo/product/components/ProductLabel.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';

export default {
  name: "SfGallery",
  components: {
    SfImage,
    SfButton,
    Flicking,
    SkeletonLoader,
    ProductLabel,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    thumbWidth: {
      type: [Number, String],
      default: null,
    },
    thumbHeight: {
      type: [Number, String],
      default: null,
    },
    current: {
      type: Number,
      default: 1,
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          type: "slider",
          autoplay: false,
          rewind: false,
          gap: 0,
        };
      },
    },
    outsideZoom: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: "img",
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    thumbImageTag: {
      type: String,
      default: "img",
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props) {
    const { imageSizes } = useProductGallery(props.product);
    const sfGalleryImageRefresh = ref(null); 

    onDeactivated(async () =>{
      await nextTick();
      sfGalleryImageRefresh.value?.destroy();
    }) 

    onActivated(async () =>{
      await nextTick();
      sfGalleryImageRefresh.value?.init();
    })

    return {
      imageSizes,
      sfGalleryImageRefresh
    };
  },
  data() {
    return {
      positionStatic: {},
      eventHover: {},
      glide: null,
      activeIndex: this.current - 1,
      triggerFlag: false,
      style: "",
      pictureSelected: this.images[0] || {
        alt: "",
        zoom: "",
        big: {
          url: "",
        },
        desktop: "",
        placeholder: "",
      },
      isZoomStarted: false,
      plugins: [new Arrow({ moveCount: 2})],
      isMobile: process.browser 
      ? (window.screen.width < 1024 || window.innerWidth < 1024) ? true : false
      : null,
    };
  },
  computed: {
    mapPictures() {
      // map images to handle picture tags with SfImage
      return this.images.map(({ desktop, big }) => ({
        mobile: desktop,
        desktop: big,
      }));
    },
    updatedSliderOptions() {
      return { ...this.sliderOptions, startAt: this.activeIndex };
    },
    definedPicture() {
      const { zoom, big, desktop } = this.pictureSelected;
      const definedPicture = zoom || big || desktop;
      definedPicture ? (definedPicture.alt = this.pictureSelected?.alt) : null;
      definedPicture
        ? (definedPicture.placeholder = this.pictureSelected?.placeholder)
        : null;
      definedPicture ? (definedPicture.video = this.pictureSelected?.video?.url) : null;
      return definedPicture ? definedPicture : "";
    },
    updateActiveIndex(){
      if(this?.images[0]?.reset == true){
        const basePosition = this?.images[0]?.current ? this?.images[0]?.current -1 : this.activeIndex;
        if(this?.images[0]?.current){
          this.go(basePosition);
        }
      }
    },
  },
  mounted() {
    this.updateScreenSize();
    if (this.$device.isDesktop) {
      window.addEventListener("resize", this.updateScreenSize);
    }
    this.$nextTick(() => {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length < 1) return;
      const glide = new Glide(this.$refs.glide, this.updatedSliderOptions);
      glide.on("run", () => {
        this.go(glide.index);
      });
      glide.mount();
      this.glide = glide;
    });
  },
  // updated() {
  //   if (this.glide) {
  //     this.$nextTick(() => {
  //       this.glide.mount();
  //     });
  //   }
  // },
  watch: {
    images() {
      if (this.glide) {
        this.$nextTick(() => {
          this.glide.update();
        });
      }
    },
  },
  beforeDestroy() {
    // comment out this because it will cause all images display at product image screen there 
    // when click redirect to other page like go to checkout page.
    if (this.glide) {
      this.glide.destroy();
    }
    if (this.$device.isDesktop) {
      window.removeEventListener("resize", this.updateScreenSize);
    }
  },
  methods: {
    positionObject(index) {
      if (this.$refs.sfGalleryBigImage) {
        if (this.outsideZoom) {
          return this.$refs.glide.getBoundingClientRect();
        } else {
          return this.$refs.sfGalleryBigImage[
            index
          ].$el.getBoundingClientRect();
        }
      }
      return "";
    },
    go(index) {
      this.pictureSelected = this.images[index];
      if (!this.glide) return;
      this.activeIndex = index;
      /**
       * Event for current image change (`v-model`)
       * @type {Event}
       */
      this.$emit("click", index + 1);
      if (this.glide) {
        this.glide.go(`=${index}`);
      }
      this.images[0].reset = false;
      this.triggerFlag = true;
    },
    startZoom() {
      if (this.enableZoom) {
        this.isZoomStarted = true;
      }
    },
    moveZoom($event, index) {
      if (this.enableZoom) {
        this.eventHover = $event;
        if (this.outsideZoom) {
          this.positionStatic = this.positionObject(index);
          this.$refs.outSide.style.cssText = `position: absolute; left: ${
            $event.clientX - this.positionStatic.x
          }px; top: ${$event.clientY - this.positionStatic.y}px; z-index: 1;`;
          this.$refs.imgZoom.$el.children[0].style.cssText = `transform: scale(2); width: 300px; height: auto;`;
          this.$refs.imgZoom.$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        } else {
          this.positionStatic = this.positionObject(index);
          this.$refs.sfGalleryBigImage[index].$el.children[0].style.cssText =
            "top: 0; transform: scale(2);";
          this.$refs.sfGalleryBigImage[
            index
          ].$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        }
      }
    },
    removeZoom(index) {
      if (this.enableZoom) {
        this.isZoomStarted = false;
        if (this.outsideZoom) return;
        this.$refs.sfGalleryBigImage[index].$el.children[0].style.transform =
          "scale(1)";
      }
    },
    updateScreenSize() {
      this.isMobile = window.screen.width < 1024 || window.innerWidth < 1024 ? true : false;
    },
  },
};
</script>
<style lang="scss">
@import url("node_modules/@egjs/vue-flicking/dist/flicking.css");
// Or, if you have to support IE9
@import url("node_modules/@egjs/vue-flicking/dist/flicking-inline.css");
@import url("node_modules/@egjs/flicking-plugins/dist/arrow.css");
@import "~/components/customSFUI/shared/styles/components/molecules/FtGallery/FtGallery.scss";

.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-camera {
  z-index: 0;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev {
  top: 3%;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next {
  top: 95%;
  right: 0;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev::before {
  left: 33px;
  transform: rotate(135deg);
  transform-origin: 30% -20%;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev::after {
  left: 33px;
  transform: rotate(45deg);
  transform-origin: 43% 200%;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next::before {
  right: 36px;
  transform-origin: 50% 0%;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next::after {
  right: 31px;
  transform: rotate(-45deg);
  transform-origin: 89% -50%;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev, 
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next {
  display: none;
  width: 80px;
  height: 30px;
  left: 10px;
  z-index: 0;
  @include for-desktop {
    display: block;
  }
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev::before,
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev::after,
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next::before,
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next::after {
  width: 17px;
  height: 3px;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-prev.is-circle, 
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-next.is-circle {
  background-color: rgba(196, 189, 173, 0.80);
  border-radius: 5px;
}
.sf-gallery .sf-gallery__thumbs .flicking-viewport .flicking-arrow-disabled.is-circle {
  display: none;
}

.sf-gallery .gallery-main-image-skeleton {
  height: 358px;
  @include for-desktop {
    height: 600px;
  }
}
.sf-gallery .gallery-thumbnail-skeleton {
  width: 60px;
  height: 60px;
  margin-right: 18px !important;
  @include for-desktop {
    width: 100px;
    height: 100px;
    margin-right: 0 !important;
  }
}
.sf-gallery a {
  display: contents;
}

.sf-gallery .product-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 1;

  .product-vid-btn {
    background-image: url('/custom/Product_Play_Button.png');
    background-size: contain;
    background-repeat: no-repeat;
    max-height: 85px;
    max-width: 85px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    height: 100%;
    @include for-desktop {
      max-width: 100px;
    }
    &.thumbs {
      max-height: 25px;
      max-width: 25px;
      @include for-desktop {
        max-height: 34px;
        max-width: 34px;
      }
    }
  }
} 
</style>
