import { useApi } from '~/composables/useApi';

export const useFtProductAcknowledgment = () => {
  const { query } = useApi();

  const getProductAcknowledgment= async (product_uid) =>{
    const myGraphqlQuery = `
      query productacknowledgment($product_id: String!){
        productacknowledgment(product_id: $product_id){
          product_id,
          title,
          message,
          acknowledgement_id
        }
      }
      `;  
    
    const { data } = await query(myGraphqlQuery, {product_id: product_uid});

    return {
      data,
    };
  }
  
  return {
    getProductAcknowledgment,
  }
}


export default useFtProductAcknowledgment;