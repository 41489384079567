<template><div id="GA4ViewItem"></div></template>
<script>
import { onMounted } from "@nuxtjs/composition-api";
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';

export default {
  name: "GA4ViewItem",
  props: {
    product: {
    type: Object,
    required: true,
    },
    activeCategory:{
    type: String,
    },
  },
  methods: {
    trackViewItem() {
      if(this?.user?.email){
        const userId = sha256.hex(this.user.email);
        this.$gtag.event("userIdSet", {
          'event_label': "userIdSet",
          'currency': this.product?.price_range?.maximum_price?.final_price?.currency ?? "", 
          'item_list_name': this.activeCategory != null ? 'Category - '+this.activeCategory : 'Category - '+this.product?.product_category ?? 'Product - '+this.product?.name,
          'items': [
            {
              item_id: this.product?.sku ?? "",
              item_name: this.product?.name ?? "",
              price: this.product?.price_range?.maximum_price?.final_price?.value ?? "",
              item_category: this.product?.product_category ?? "",
              item_brand: this.product?.product_brand_label ?? "",
            }
          ],
          'user_id': userId
        });
      }

      this.$gtag.event("view_item", {
        'event_label': "view_item",
        'currency': this.product?.price_range?.maximum_price?.final_price?.currency ?? "", 
        'item_list_name': this.activeCategory != null ? 'Category - '+this.activeCategory : 'Category - '+this.product?.product_category ?? 'Product - '+this.product?.name,
        'items': [
          {
            item_id: this.product?.sku ?? "",
            item_name: this.product?.name ?? "",
            price: this.product?.price_range?.maximum_price?.final_price?.value ?? "",
            item_category: this.product?.product_category ?? "",
            item_brand: this.product?.product_brand_label ?? "",
          }
        ]
      });
    },
  },
  mounted(){
    if(this.product){
      this.trackViewItem();
    }
  },
  activated(){
    if(this.product){
      this.trackViewItem();
    }
  },
  setup() {
    const { user, load: loadUser } = useUser();
    onMounted(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });
    return{
      user
    }
  }
};
</script>

  