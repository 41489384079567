var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-gallery"},[_c('div',{staticClass:"sf-gallery__stage"},[_c('div',{ref:"glide",staticClass:"glide",class:{'loading': !_vm.glide}},[_c('div',{staticClass:"glide__track",attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides"},_vm._l((_vm.images),function(picture,index){return _c('li',{key:'slide-' + index,staticClass:"glide__slide",on:{"mouseover":function($event){return _vm.startZoom(picture)},"mousemove":function($event){return _vm.moveZoom($event, index)},"mouseout":function($event){return _vm.removeZoom(index)}}},[_c('a',{attrs:{"href":picture.original.url,"data-pswp-width":3000,"data-pswp-height":2500,"target":"_blank","rel":"noreferrer"}},[_vm._t("big-image",function(){return [_c('SfImage',{ref:"sfGalleryBigImage",refInFor:true,staticClass:"sf-gallery__big-image",class:{ 'sf-gallery__big-image--has-zoom': _vm.enableZoom },attrs:{"src":picture.desktop.url,"alt":picture.alt,"placeholder":picture.placeholder,"width":_vm.imageWidth,"height":_vm.imageHeight,"image-tag":_vm.imageTag,"nuxt-img-config":_vm.nuxtImgConfig,"loading":"eager"}})]},null,{
                enableZoom: _vm.enableZoom,
                picture: picture,
                index: index,
                imageWidth: _vm.imageWidth,
                imageHeight: _vm.imageHeight,
                imageTag: _vm.imageTag,
                nuxtImgConfig: _vm.nuxtImgConfig,
              })],2)])}),0)])]),_vm._v(" "),(!_vm.glide)?_c('SkeletonLoader',{staticClass:"gallery-main-image-skeleton",attrs:{"width":"100%","radius":"30px","margin":"0"}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade"}},[_c('div',{ref:"outSide",class:{
          'display-none':
            !_vm.outsideZoom || !_vm.isZoomStarted || (!_vm.outsideZoom && !_vm.enableZoom),
        },style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") })},[_vm._t("outside-zoom",function(){return [_c('SfImage',{ref:"imgZoom",staticClass:"sf-gallery__zoom",attrs:{"src":_vm.definedPicture.url,"width":_vm.imageWidth,"height":_vm.imageHeight,"lazy":false,"alt":_vm.definedPicture.alt,"placeholder":_vm.definedPicture.placeholder,"image-tag":_vm.imageTag,"nuxt-img-config":_vm.nuxtImgConfig}})]},null,{
            definedPicture: _vm.definedPicture,
            imageWidth: _vm.imageWidth,
            imageHeight: _vm.imageHeight,
            imageTag: _vm.imageTag,
            nuxtImgConfig: _vm.nuxtImgConfig,
          })],2)]),_vm._v(" "),(_vm.definedPicture.video)?_c('div',{staticClass:"product-vid",on:{"click":function($event){return _vm.$emit('playVideo', _vm.definedPicture.video)}}},[_c('span',{staticClass:"product-vid-btn"})]):_vm._e(),_vm._v(" "),(_vm.product.uid)?_c('ProductLabel',{attrs:{"product":_vm.product,"imageSize":_vm.imageSizes.productCard}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"sf-gallery__thumbs"},[_vm._t("thumbs",function(){return [(_vm.glide)?_c('Flicking',{ref:"sfGalleryImageRefresh",attrs:{"options":{ align: 'prev', circular: false, horizontal: _vm.isMobile, moveType: 'freeScroll', bound: true },"plugins":_vm.plugins}},[_vm._l((_vm.images),function(image,index){return _c('SfButton',{key:'img-' + index,staticClass:"sf-button--pure sf-gallery__item",class:{ 'sf-gallery__item--selected': index === _vm.activeIndex },attrs:{"aria-label":'Image ' + index},on:{"click":function($event){return _vm.go(index)}}},[(image.video.url)?_c('div',{staticClass:"product-vid",on:{"click":function($event){return _vm.$emit('playVideo', image.video.url)}}},[_c('span',{staticClass:"product-vid-btn thumbs"})]):_vm._e(),_vm._v(" "),_c('SfImage',{staticClass:"sf-gallery__thumb",attrs:{"src":image.mobile.url,"alt":image.alt,"placeholder":image.placeholder,"width":_vm.thumbWidth,"height":_vm.thumbHeight,"image-tag":_vm.thumbImageTag,"nuxt-img-config":_vm.thumbNuxtImgConfig,"loading":"eager","placeholder":"/custom/fortytwo-loading-icon.gif"}})],1)}),_vm._v(" "),_c('span',{staticClass:"flicking-arrow-prev is-circle",attrs:{"slot":"viewport"},slot:"viewport"}),_vm._v(" "),_c('span',{staticClass:"flicking-arrow-next is-circle",attrs:{"slot":"viewport"},slot:"viewport"})],2):_vm._l((4),function(n){return _c('SkeletonLoader',{key:n,staticClass:"gallery-thumbnail-skeleton",attrs:{"radius":"8px"}})})]},null,{ images: _vm.images, active: _vm.activeIndex, go: _vm.go, updateActiveIndex: _vm.updateActiveIndex })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }