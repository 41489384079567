






































import { ref, defineComponent, computed } from '@nuxtjs/composition-api';
import { SfChevron } from '@storefront-ui/vue';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'FortyTwoProductInfoSpec',
  components: {
    HTMLContent,
    SfChevron,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isProductTabOpen = ref(false);
    const staffRating = computed(() => props.product?.product_staff_rating_label || 0);
    const additionalData = computed(() => props.product?.product_additional_data || '');

    const updDecimal = (value) => {
       if (value % 1 === 0) {
         return parseFloat(value);
       }

       return parseFloat(value).toFixed(2);
    };

    return {
        isProductTabOpen,
        staffRating,
        additionalData,
        updDecimal,
    }
  }
});
