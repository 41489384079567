










































import { defineComponent, ref, computed, watch, PropType, onMounted } from "@nuxtjs/composition-api";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
    name: "ProductDeliveryInfo",
    components: {
      SkeletonLoader,
    },
    props:["isLoadingDelivery", "productDelivery", 
    "standardDeliveryDateDisplay", "canExpress", "expressDeliveryGroupName", 
    "expressDeliveryEarliestTimeslot", "expressDeliveryDayDisplay"
    ],
    setup() {
        
    }
})
